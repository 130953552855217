import * as yup from 'yup';
import { ButtonType, ELocale, EToastType } from 'types';
import { Container, ExtraInfo, OptionalText } from './assets/elements';
import { EMAIL_REGEXP } from './assets/constants';
import { ERECIPIENTS } from './assets/types';
import { ETrackingEvent } from 'types/tracking';
import { UseFormReturn, useForm } from 'react-hook-form';
import { formText } from './assets/swedishFormsTexts';
import { spaceXl } from 'styles/variables';
import { useEffect } from 'react';
import { useToast } from 'contexts/Toast';
import { yupResolver } from '@hookform/resolvers/yup';
import ApiError from 'classes/ApiError';
import Button from 'components/clickables/Button';
import Form from 'components/forms/Form';
import Page from 'components/layout/Page';
import ReactGA from 'react-ga4';
import TextArea from '../../components/forms/TextArea';
import TextInput from 'components/forms/TextInput';
import i18n from 'i18n';
import styled from 'styled-components';
import useSendEmail from './assets/useSendEmail';

type FormType = {
    name: string;
    email: string;
    chargeKeyNumber: string;
    message?: string;
};

const StyledContainer = styled(Container)`
    margin-top: ${spaceXl};
`;

const FormSchema = (): yup.ObjectSchema<FormType> =>
    yup.object().shape({
        name: yup.string().required(formText.required),
        email: yup.string().required(formText.required).matches(EMAIL_REGEXP, { message: formText.emailFormatError }),
        chargeKeyNumber: yup.string().required(formText.required),
        message: yup.string(),
    });

function CancelSubscription(): JSX.Element {
    useEffect(() => {
        i18n.changeLanguage(ELocale.SV);
    }, []);

    const formMethods = useForm<FormType>({
        mode: 'onBlur',
        resolver: yupResolver(FormSchema()),
        shouldUnregister: false,
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = formMethods;

    const { addToast } = useToast();
    const { sendEmail } = useSendEmail();

    const generateEmailBody = (data: FormType): string => {
        return (
            `Namn: ${data.name}\n` +
            `E-postadress: ${data.email}\n` +
            `Nummer på din laddbricka: ${data.chargeKeyNumber}\n` +
            `Specifika önskemål avseende uppsägningen: ${data.message ? data.message : '-'}\n`
        );
    };

    const onSubmit = async (data: FormType): Promise<void> => {
        ReactGA.event(ETrackingEvent.SUBMIT_FORM_CANCEL_SUBSCRIPTION);
        try {
            await sendEmail.mutateAsync({
                subject: 'Uppsägning av abonnemang',
                body: generateEmailBody(data),
                recipient: ERECIPIENTS.MER_CUSTOMER_SERVICE,
            });
            reset();
            addToast({ message: 'Uppsägning skickad', type: EToastType.SUCCESS });
        } catch (error) {
            if (error instanceof ApiError) {
                addToast({ message: formText.submitError, type: EToastType.ERROR });
            }
        }
    };

    return (
        <Page title="Uppsägning av abonnemang">
            <Form onSubmit={handleSubmit(onSubmit)} formMethods={formMethods as unknown as UseFormReturn}>
                <StyledContainer>
                    <TextInput required label={formText.name} fieldError={errors.name} {...register('name')} />
                    <TextInput label={formText.email} required fieldError={errors.email} {...register('email')} />
                    <TextInput
                        label={
                            <>
                                Nummer på din laddbricka
                                <ExtraInfo>Finner du på baksidan av laddbrickan eller kortet</ExtraInfo>
                            </>
                        }
                        required
                        fieldError={errors.chargeKeyNumber}
                        {...register('chargeKeyNumber')}
                    />
                    <TextArea
                        label={
                            <>
                                Har du några specifika önskemål avseende uppsägningen?
                                <OptionalText>(valfritt)</OptionalText>
                                <ExtraInfo>
                                    Observera att våra fastprisabonnemang har en uppsägningstid på 30 dagar.
                                </ExtraInfo>
                            </>
                        }
                        hideOptionalText
                        maxLength={500}
                        {...register('message')}
                    />
                </StyledContainer>
                <Button dataTestId="submit" type={ButtonType.SUBMIT} isLoading={sendEmail.isPending}>
                    {formText.submit}
                </Button>
            </Form>
        </Page>
    );
}

export default CancelSubscription;
